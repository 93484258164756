import React from 'react'
import PropTypes from 'prop-types'
import visaLogo from "../images/Visa-logo.png"
import masterLogo from "../images/mc_symbol.svg"
import pumbLogo from "../images/pumb.png"

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; <strong>Trusta LLC, всі права захищено</strong></p>
        <div style={{display:"flex",justifyContent:"center",flexDirection:"row",flexWrap:"wrap",marginTop:"10px"}}>
            <div style={{flexShrink:"1",width:"auto",height:"30px"}}>
                <img src={visaLogo} style={{height:"25px",width:"auto",paddingRight:"12px"}} alt="visa branding logo"/>
            </div>
            <div style={{flexShrink:"1",borderLeft:"2px solid rgba(255,255,255,0.5)", height:"22px",marginTop:"3px"}}></div>
            <div style={{flexShrink:"1",width:"auto",}}>
                <img src={masterLogo} style={{height:"30px",width:"auto",paddingLeft:"10px",paddingRight:"10px"}} alt="mastercard branding logo"/>
            </div>
            <div style={{flexShrink:"1",borderLeft:"2px solid rgba(255,255,255,0.5)", height:"22px",marginTop:"3px"}}></div>
            <div style={{flexShrink:"1",width:"auto",}}>
                <a href="https://pumb.ua" style={{borderBottom:"0px"}} target="_blank" rel="noopener noreferrer">
                    <img src={pumbLogo} style={{height:"30px",width:"auto",paddingLeft:"10px"}} alt="mastercard branding logo"/>
                </a>
            </div>
        </div>
        <div style={{display:"flex",justifyContent:"center",flexDirection:"column",flexWrap:"wrap",marginTop:"10px"}} >
            <p className="copyright" style={{fontSize:"0.5rem" }}>
                <strong>Офіс ТОВ "ТРАСТА":  Україна, м.Теплодар, вул. Піонерна 7</strong>
            </p>
            
            <p className="copyright" style={{textTransform:"none",padding:"5px 0px",fontSize:"0.5rem" }}>
               <strong> Питання співпраці: </strong> <a href="mailto:office@trusta-pay.com" >office@trusta-pay.com</a>
            </p>
            <p className="copyright" style={{textTransform:"none","padding":"5px 0",fontSize:"0.5rem" }}>
                <strong>Служба підтримки: </strong> <a href="mailto:support@trusta-pay.com" >support@trusta-pay.com</a>
            </p>

        </div>
       
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
